<template>
  <div id="portals" class="vx-row mt-6 flex justify-start">
    <div v-for="p in portals" :key="p.info.portal_slug" 
      class="vx-col w-full xs:w-full sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/3 p-4">
      <vx-card
        :title="`${p.info.portal_nicename}`"
        :subtitle="`id: ${p.info.portal_id}, slug: ${p.info.portal_slug}`"
        @click="$router.push(`/admin/portal/${p.info.portal_slug}`).catch(() => {})"
        class="cursor-pointer"
        >
        <div class="vx-row w-full chip-line mt-0">
          <vs-chip color="blue" v-if="p.info.beta_tester == 1">beta tester</vs-chip>
        </div>
        <div>
          <div class="mt-3">
            <h6>{{$t('timeUsage')}}</h6>
            <vs-progress height="8" :percent="parseFloat(p.subscription.time_percent) * 100" :color="getSizeColor(p.subscription.time_percent * 100)" class="block mt-1 shadow-md"></vs-progress>
            <span>{{ p.subscription.start_access | shortDate }} → {{ p.subscription.end_access | shortDate }}</span>
          </div>
          <div class="mt-3">
            <h6>{{$t('spaceUsage')}}</h6>
            <vs-progress height="8" :percent="parseFloat(p.subscription.space_used_percent) * 100" :color="getSizeColor(p.subscription.space_used_percent * 100)" class="block mt-1 shadow-md"></vs-progress>
            <span>{{ Number(p.subscription.space_used) | prettyBytes }} / {{ Number(p.subscription.space_total) | prettyBytes }}</span>
          </div>
          <div class="mt-3">
            <h6>{{$t('layersUsage')}}</h6>
            <vs-progress height="8" :percent="parseFloat(p.subscription.space_used_percent) * 100" :color="getSizeColor(p.subscription.layers_used_percent * 100)" class="block mt-1 shadow-md"></vs-progress>
            <span>{{ Number(p.subscription.layers_used) }} / {{ Number(p.subscription.layers_total) }}</span>
          </div>
          <div class="mt-3">
            <h6>{{$t('projectsUsage')}}</h6>
            <vs-progress height="8" :percent="parseFloat(p.subscription.projects_used_percent) * 100" :color="getSizeColor(p.subscription.projects_used_percent * 100)" class="block mt-1 shadow-md"></vs-progress>
            <span>{{ Number(p.subscription.projects_used) }} / {{ Number(p.subscription.projects_total) }}</span>
          </div>
          <div class="mt-3">
            <h6>{{$t('usersUsage')}}</h6>
            <vs-progress height="8" :percent="parseFloat(p.subscription.users_used_percent) * 100" :color="getSizeColor(p.subscription.users_used_percent * 100)" class="block mt-1 shadow-md"></vs-progress>
            <span>{{ Number(p.subscription.users_used) }} / {{ Number(p.subscription.users_total) }}</span>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>
<script>
import axios from '@/axios.js'
import vSelect from 'vue-select'
import moment from 'moment'
import VueApexCharts from 'vue-apexcharts'
import apexChatData from 'apexcharts'

import Datepicker from 'vuejs-datepicker'

export default {
  components: {
    moment,
    VueApexCharts, 
    vSelect, 
    Datepicker
  },
  data () {
    return {
      apexChatData,
      api_server_baseurl: this.$appConfig.apiBaseUrl,
      appConfig: this.$appConfig,
      portals: {}
    }
  },
  methods: {   
    refreshAll () {
      this.getPortalsInfos()
    },
    getPortalsInfos () {
      const params = ''
      const rimnat_api_call_url = `${this.api_server_baseurl}/admin/portal/getPortalsSynthesis?${params}`
      axios.get(rimnat_api_call_url, {
      })
        .then((response) => { 
          console.log(response)
          this.portals = response.data
        })
        .catch((error)   => { console.log(error) })
    },
    getValues (serie) {
      return Object.entries(serie)
        .map(item => parseFloat(item[1].result))
    },
    getLabels (serie) {
      return Object.entries(serie)
        .map(function (item) {
          let label = Object.values(item[1])[0]
          const maxKey = Object.keys(item[1]).length - 2
          for (let i = 1; i <= maxKey; i++) {
            const val = `0${Object.values(item[1])[i]}`.slice(-2)
            label = `${label}.${val}`
          }
          return label
        })
    }, 
    getSizeColor (num) {
      if (num > 80)  return 'danger'
      if (num > 60)  return 'warning'
      if (num >= 40) return 'primary'
      if (num < 40)  return 'success'
      return 'primary'
    }
  },
 
  computed: {
    startDate () {
      return new Date(this.start).toISOString().split('T')[0]
    },
    endDate () {
      return new Date(this.end).toISOString().split('T')[0]
    }
  },

  beforeMount () {
  },

  mounted () {
    document.body.classList.add('theme-dark')
    this.refreshAll()
  },

  watch: {
    scale () {
      this.refreshAll()
    },
    start () {
      this.refreshAll()
    },
    end () {
      this.refreshAll()
    }
  }
}

</script>
<style lang="scss">

.theme-dark input {
    background: none !important;
}
</style>
